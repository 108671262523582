import {useEffect, useRef, useState} from "react";
import {Collapse} from "bootstrap";

export default function useCreateCollapse() {
    const hideButtonRef = useRef();
    const collapseRef = useRef();
    const createButtonRef = useRef();

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const collapseElement = collapseRef.current;
        const hideElement = hideButtonRef.current;
        const createElement = createButtonRef.current;

        const collapseInstance = Collapse.getOrCreateInstance(collapseElement,  {
            toggle: false
        });

        function hiddenListener(evt) {
            setOpen(false);
        }
        function shownListener(evt) {
            setOpen(true);
        }

        function hideListener() {
            collapseInstance.hide();
        }

        function showListener() {
            collapseInstance.show();
        }

        collapseElement.addEventListener('hidden.bs.collapse', hiddenListener);
        collapseElement.addEventListener('shown.bs.collapse', shownListener);

        createElement.addEventListener("click", showListener);
        hideElement.addEventListener("click", hideListener);

        return () => {
            createElement.removeEventListener("click", showListener);
            hideElement.removeEventListener("click", hideListener);
            collapseElement.removeEventListener('hidden.bs.collapse', hiddenListener);
            collapseElement.removeEventListener('shown.bs.collapse', shownListener);
        };
    }, []);

    return {
        collapseRef, createButtonRef, hideButtonRef, open
    };
};
import {useContext, useEffect, useState} from "react";
import Error from "../../components/Alerts/Error";
import Spinner from "../../components/Spinner";
import {authContext} from "../../context/auth";
import AddPlaceHolder from "./AddPlaceHolder";
import DeletePlaceHolder from "./DeletePlaceHolder";
import usePlaceHolders from "../../hooks/usePlaceHolders";

export default function PlaceHolders({promptId, deletePlaceHolder, setPlaceHolders, placeHolders, error, loading}) {

	return (
		<>
			{error && <Error message={error}/>}

			{!loading && <AddPlaceHolder setPlaceHolders={setPlaceHolders} promptId={promptId}/>}

			<div className="text-center">
				{loading && <Spinner/>}
			</div>

			{!loading && <table className={"table table-sm table-striped mt-3"}>
				<thead className={"table-dark"}>
				<tr>
					<th>Name</th>
					<th>Is in file</th>
					<th></th>
				</tr>
				</thead>
				<tbody>
				{placeHolders.map(value => {
					return <tr key={value.id}>
						<td className={"w-100"}>{value.name}</td>
						<td>{value.isInFile}</td>
						<td><DeletePlaceHolder deletePlaceHolder={deletePlaceHolder} placeHolder={value} /></td>
					</tr>
				})}
				</tbody>
			</table>}
		</>
	)
}


export default function PreviewFile({rows, placeHolders}) {
	return (
		<div className={"mb-3"}>
			<button className={"btn btn-sm btn-secondary"} type={"button"} data-bs-toggle="modal" data-bs-target="#previewFileModal">Preview file</button>

			<div className="modal fade" id="previewFileModal" tabIndex="-1" aria-labelledby="previewFileModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-xl modal-dialog-scrollable">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="previewFileModalLabel">Preview file</h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body">
							<table className={"table table-sm table-striped"}>
								<caption>Rows that will be analyzed</caption>
								<thead className={"table-dark"}>
									<tr>
										{placeHolders.map((value, index) => {
											return <th key={index}>{value.placeHolderName}</th>
										})}
									</tr>
								</thead>
								<tbody>
								{rows.map((value, index) => {
									return <tr key={index}>
										{placeHolders.map((placeHolder, index) => {
											return <td key={index}>{value[placeHolder.value]}</td>
										})}
									</tr>;
								})}
								</tbody>
							</table>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

import {createContext, useEffect, useState} from "react";

export const apiKeyContext = createContext({
	openAiApiKey: "",
	setOpenAiApiKey: (apiKey) => {},
	anthropicApiKey: "",
	setAnthropicApiKey: (apiKey) => {},
	mistralApiKey: "",
	setMistralApiKey: (apiKey) => {}
});
export const ApiKeyProvider = function ({children}) {
	const [openAiApiKey, setOpenAiApiKey] = useState("");
	const [anthropicApiKey, setAnthropicApiKey] = useState("");
	const [mistralApiKey, setMistralApiKey] = useState("");

	useEffect(() => {
		const openAiApiKey= localStorage.getItem('openAiApiKey');
		if (openAiApiKey) {
			setOpenAiApiKey(openAiApiKey);
		}
		const anthropicApiKey= localStorage.getItem('anthropicApiKey');
		if (anthropicApiKey) {
			setAnthropicApiKey(anthropicApiKey);
		}
		const mistralApiKey= localStorage.getItem('mistralApiKey');
		if (mistralApiKey) {
			setMistralApiKey(mistralApiKey);
		}
	}, []);

	useEffect(() => {
		if (openAiApiKey) {
			localStorage.setItem('openAiApiKey', openAiApiKey);
		}
		if (anthropicApiKey) {
			localStorage.setItem('anthropicApiKey', anthropicApiKey);
		}
		if (mistralApiKey) {
			localStorage.setItem('mistralApiKey', mistralApiKey);
		}
	}, [openAiApiKey, anthropicApiKey, mistralApiKey]);

	return (
		<apiKeyContext.Provider value={{
			openAiApiKey,anthropicApiKey,
			setOpenAiApiKey,setAnthropicApiKey,
			mistralApiKey, setMistralApiKey
		}}>
			{children}
		</apiKeyContext.Provider>
	);
};

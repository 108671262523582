import Container from "../../components/Container";
import {useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import Error from "../../components/Alerts/Error";
import Spinner from "../../components/Spinner";
import {authContext} from "../../context/auth";
import Results from "./Results";

export default function IndividualJob() {
    const {jobId} = useParams();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [job, setJob] = useState({});

    const {token} = useContext(authContext);

    useEffect(() => {
        const abortController = new AbortController();
        let isMounted = true;

        (async () => {
            setLoading(true);
            try {
                const response = await fetch(process.env.REACT_APP_ENDPOINT + '/jobs/' + jobId, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                    signal: abortController.signal
                });
                if (response.status === 200) {
                    const {job} = await response.json();
                    isMounted && setJob(job);
                }
                else {
                    throw new Error("Wrong status.");
                }
            }
            catch (e) {
                console.error(e);
                isMounted && setError('Failed to fetch job.');
            }

            isMounted && setLoading(false);
        })();

        return () => {
            isMounted = false;
            abortController.abort();
        };
    }, [jobId]);
    return (
        <Container>
            {error && <Error message={error} />}
            <div className="text-center">
                {loading && <Spinner />}
            </div>

            {!loading &&
                <table className={"table table-light table-sm table-striped"}>
                    <caption className={"caption-top"}>Parameters for job</caption>
                    <tbody>
                    <tr>
                        <th>Prompt:</th>
                        <td>{job.promptName}</td>
                    </tr>
                    <tr>
                        <th>File:</th>
                        <td>{job.fileName}</td>
                    </tr>
                    <tr>
                        <th>Model:</th>
                        <td>{job.model}</td>
                    </tr>
                    <tr>
                        <th>Tokens (prompt, completion, total):</th>
                        <td>{job.usage && job.usage.prompt_tokens + ', ' + job.usage.completion_tokens + ', ' + job.usage.total_tokens}</td>
                    </tr>
                    </tbody>
                </table>
            }

            <hr />
            <Results jobId={jobId} />
        </Container>
    );
};

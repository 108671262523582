import {useContext, useState} from "react";
import Spinner from "../../components/Spinner";
import {authContext} from "../../context/auth";

export default function DeleteMessage({message, deleteMessage}) {
	const [loading, setLoading] = useState(false);

	async function onClick() {
		setLoading(true);

		try {
			await deleteMessage(message.id);
		}
		catch (e) {
			console.error(e);
		}
		setLoading(false);
	}
	return (
		<button type={"button"} className={"btn btn-danger"} disabled={loading} onClick={onClick}>Delete {loading && <Spinner small={true} />}</button>
	);
};

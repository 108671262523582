import Messages from "./Messages";
import {useContext, useEffect, useState} from "react";
import {authContext} from "../../context/auth";
import useBackDrops from "../../hooks/useBackDrops";
import PlaceHolders from "./PlaceHolders";
import Spinner from "../../components/Spinner";

export default function DeletePrompt({prompt, deletePrompt}) {
	useBackDrops();
	const [loading, setLoading] = useState(false);

	async function onClick() {
		setLoading(true);
		await deletePrompt(prompt.id);
		setLoading(false);
	}
	const id = `deletePrompt${prompt.id}`;

	return (
		<>
			<button type="button" className="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target={`#${id}`}>
				Delete prompt
			</button>

			<div className="modal fade" id={id} tabIndex="-1" aria-labelledby={`${id}Label`} aria-hidden="true">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id={`${id}Label`}>Delete prompt</h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body">
							<p>Are you sure you want to delete this prompt?</p>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							<button type="button" className="btn btn-danger" onClick={onClick} disabled={loading}>{loading && <Spinner small={true}/>} Yes</button>
						</div>
					</div>
				</div>
			</div>
			{/*<div classNameName={"border-bottom p-3"}>
				<div classNameName={"mb-3"}>
					<label htmlFor={`name${prompt.id}`} classNameName={"form-label"}><b>Name:</b></label>
					<input type={"text"} id={`name${prompt.id}`} placeholder={"Prompt number 1"}
						   classNameName={"form-control"} value={prompt.name || ''} onChange={evt => {
						editPrompt(prompt.id, {
							name: evt.target.value
						});
					}}/>
				</div>
				<div classNameName={"mb-3"}>
					<label htmlFor={`systemRole${prompt.id}`} classNameName={"form-label"}>System:</label>
					<textarea id={`systemRole${prompt.id}`} placeholder={"You are a helpful assistant."}
							  classNameName={"form-control"} value={prompt.system || ''} onChange={evt => {
						editPrompt(prompt.id, {
							system: evt.target.value
						});
					}}></textarea>
				</div>

				<hr/>
				<Messages promptId={prompt.id} deletePrompt={deletePrompt}/>
			</div>*/}
		</>

	);
}

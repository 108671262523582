import {createContext, useContext, useEffect, useState} from "react";
import {authContext} from "./auth";

export const promptsContext = createContext({
	prompts: [],
	setPrompts: (prompts) => {},
	loading: false,
	error: null
});

export function PromptsProvider({children}) {
	const [prompts, setPrompts] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const {token} = useContext(authContext);

	useEffect(() => {
		let isMounted = true;
		setLoading(true);
		setError(null);
		const abortController = new AbortController();

		(async () => {
			try {
				const response = await fetch(process.env.REACT_APP_ENDPOINT + '/prompts', {
					headers: {
						'Authorization': 'Bearer ' + token
					},
					signal: abortController.signal
				});
				if (response.status === 200) {
					const json = await response.json();
					isMounted && setPrompts(json.prompts);
				}
				else {
					isMounted && setError("Failed to fetch your prompts.");
				}
			}
			catch (e) {
				console.error(e);
				isMounted && setError("Failed to fetch your prompts.");
			}
			finally {
				console.log(isMounted, 'set loading');
				isMounted && setLoading(false);
			}
		})();

		return () => {
			isMounted = false;
			abortController.abort();
		}
	}, []);

	return (
		<promptsContext.Provider value={{prompts, setPrompts, loading, error}}>
			{children}
		</promptsContext.Provider>
	);
}

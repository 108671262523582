import {useContext, useState} from "react";
import {authContext} from "../../context/auth";
import Spinner from "../../components/Spinner";
import Error from "../../components/Alerts/Error";

export default function AddMessage({setMessages, promptId}) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const {token} = useContext(authContext);

	async function addMessage() {
		setError(null);
		setLoading(true);

		try {
			const response = await fetch(process.env.REACT_APP_ENDPOINT + '/messages', {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + token
				},
				body: JSON.stringify({promptId: promptId, type: 'user', text: ''})
			});
			if (response.status === 201) {
				const {message} = await response.json();
				setMessages(prev => {
					return [...prev, {
						text: '',
						type: 'user',
						id: message.id
					}];
				});
			}
			else {
				setError("Failed to create message.");
			}
		}
		catch (e) {
			console.error(e);
			setError("Failed to create message.");
		}
		finally {
			setLoading(false);
		}
	}

	return (
		<>
			{error && <Error message={error} />}
			<button onClick={addMessage} type={"button"} disabled={loading} className={"btn btn-success mt-3"}>Add message {loading && <Spinner small={true} />}</button>
		</>
	);
}

import Error from "../../components/Alerts/Error";
import Spinner from "../../components/Spinner";
import Container from "../../components/Container";
import {useContext, useEffect, useState} from "react";
import {authContext} from "../../context/auth";
import {Navigate} from "react-router-dom";

export default function Users() {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);

    const {token, user} = useContext(authContext);

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        setError(null);
        const abortController = new AbortController();

        (async () => {
            try {
                const response = await fetch(process.env.REACT_APP_ENDPOINT + '/users', {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                    signal: abortController.signal
                });
                if (response.status === 200) {
                    const json = await response.json();
                    isMounted && setUsers(json.users);
                }
                else {
                    isMounted && setError("Failed to fetch the users.");
                }
            }
            catch (e) {
                console.error(e);
                if (e.name !== "AbortError") {
                    isMounted && setError("Failed to fetch the users.");
                }
            }
            finally {
                isMounted && setLoading(false);
            }
        })();

        return () => {
            isMounted = false;
            abortController.abort();
        }
    }, []);

    if (!user.isAdmin) {
        return <Navigate to={'/playground'}/>;
    }
    return (
        <Container>
            {error && <Error message={error} />}
            <div className="text-center">
                {loading && <Spinner />}
            </div>

            {!loading && <table className={"table table-striped"}>
                <caption className={"caption-top"}>Users</caption>
                <thead className={"table-dark"}>
                <tr>
                    <th>Email</th>
                    <th># of jobs</th>
                    <th>Last job</th>
                    <th>Company name</th>
                    <th>Company role</th>
                </tr>
                </thead>
                <tbody>
                {users.map(value => {
                    return <tr key={value.id}>
                        <td>{value.email}</td>
                        <td>{value.amountOfJobs}</td>
                        <td>{value.lastJob}</td>
                        <td>{value.companyName}</td>
                        <td>{value.companyRole}</td>
                    </tr>;
                })}
                </tbody>
            </table>
            }
        </Container>
    );
};
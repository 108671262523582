import ApiKey from "../../components/ApiKey/ApiKey";
import Container from "../../components/Container";
import DeleteAccount from "../../components/DeleteAccount";
import {Route, Routes} from "react-router-dom";

export default function Settings() {
    return (
        <Routes>
            <Route path={'apiKeys'} element={<Container>
                <ApiKey/>
            </Container>}/>
            <Route path={'deleteMyAccount'} element={<Container>
                <DeleteAccount />
            </Container>}/>
        </Routes>
    );
};
import {useContext, useState} from "react";
import {authContext} from "../../context/auth";
import {saveAs} from "file-saver";
import Spinner from "../../components/Spinner";

export default function ExportResults({job}) {
    const [loading, setLoading] = useState(false);
    const {token} = useContext(authContext);

    async function onClick() {
        setLoading(true);
        try {
            const response = await fetch(process.env.REACT_APP_ENDPOINT + '/jobs/' + job.id + '/export', {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            });
            if (response.status === 200) {
                const blob = await response.blob();
                saveAs(blob, `${job.fileName}.export_${new Date().toLocaleDateString()}.csv`);
            }
        }
        catch (e) {
            console.error(e);
        }
        setLoading(false);
    }
    return (
        <button className={"btn btn-sm btn-success ms-2"} onClick={onClick} type={"button"} disabled={loading || job.status !== "Finished"}>{loading && <Spinner small={true} />} Export</button>
    )
}
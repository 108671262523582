import usePlaceHolders from "../../hooks/usePlaceHolders";

export default function PlaceHolderSelector({columns, jobPlaceHolders, placeHolders, setJobPlaceHolders}) {
    const inFilePlaceHolders = placeHolders.filter(value => value.isInFile);
    const outsideFilePlaceHolders = placeHolders.filter(value => !value.isInFile);

    function onChange(placeHolderId, value) {
        setJobPlaceHolders(prev => {
            let newState = [...prev];
            const foundIndex = newState.findIndex(value => value.placeHolderId === placeHolderId);
            if (foundIndex !== -1) {
                if (value === "") {
                    newState = newState.filter((value, index) => index !== foundIndex);
                }
                else {
                    newState[foundIndex].value = value;
                }
            }
            else {
                newState.push({
                    placeHolderId,
                    value,
                    placeHolderName: placeHolders.find(value => value.id === placeHolderId).name
                });
            }
            return newState;
        });
    }

    return (
        <div className={"row mb-3"}>
            <div className={"col"}>
                <h5>Inside file placeholders</h5>
                {inFilePlaceHolders.map(placeHolder => {
                    return (
                        <div key={placeHolder.id} className={"mb-3"}>
                            <label htmlFor={"placeHolders" + placeHolder.id} className={"form-label"}>{placeHolder.name}:</label>
                            <select id={"placeHolders" + placeHolder.id} className={"form-select"} onChange={(evt) => {
                                onChange(placeHolder.id, evt.target.value);
                            }} value={jobPlaceHolders.find(value => value.placeHolderId === placeHolder.id)?.value || ''}>
                                <option value="">Select a column</option>
                                {columns.map((value, index) => {
                                    return <option value={index} key={index}>{value}</option>;
                                })}
                            </select>
                        </div>
                    )
                })}
            </div>
            <div className={"col"}>
                <h5>Outside file placeholders</h5>
                {outsideFilePlaceHolders.map(placeHolder => {
                    return (
                        <div key={placeHolder.id} className={"mb-3"}>
                            <label htmlFor={"placeHolders" + placeHolder.id} className={"form-label"}>{placeHolder.name}:</label>
                            <input id={"placeHolders" + placeHolder.id} className={"form-control"} onChange={(evt) => {
                                onChange(placeHolder.id, evt.target.value);
                            }} value={jobPlaceHolders.find(value => value.placeHolderId === placeHolder.id)?.value || ''} />
                        </div>
                    )
                })}
            </div>
        </div>
    );
}
import {forwardRef, useEffect, useRef, useState} from "react";
import Papa from "papaparse";

export default function FileSelector({setColumns, setRows, reset, file, setFile}) {
	const [error, setError] = useState("");
	const fileRef = useRef();

	useEffect(() => {
		if (fileRef.current.files.length === 0 && file) {
			let container = new DataTransfer();
			container.items.add(file);
			fileRef.current.files = container.files;
		}
	}, [file]);
	async function onChange(evt) {
		setError("");
		reset();

		const file = fileRef.current.files[0];
		if (file) {
			let columns = [];
			let rows = [];

			const extension = file.name.split('.').pop();

			const allowedExtensions = ["tsv", "csv"];

			if (allowedExtensions.includes(extension)) {
				const delimiter = extension === "csv" ? ",": "\t";
				const quoteChar = extension === "csv" ? "\"": "";

				try {
					await new Promise((resolve, reject) => {
						Papa.parse(file, {
							delimiter,
							quoteChar,
							preview: 1501,
							skipEmptyLines: true,
							complete: (results) => {
								columns = results.data[0];
								rows = results.data.slice(1);
								resolve()
							},
							error: reject
						});
					});
					setColumns(columns);
					setRows(rows);
					setFile(file);
				}
				catch (e) {
					console.error(e);
				}
			}
			else {
				setError("Please choose a csv or tsv file.");
			}
		}
	}

	let fileClassName = "form-control";

	if (error) {
		fileClassName += " is-invalid";
	}

	return (
		<div className="mb-3">
			<label htmlFor="formFile" className="form-label">File (csv/tsv):</label>
			<input ref={fileRef} onChange={onChange} className={fileClassName} type="file" id="formFile" />
			<div className="invalid-feedback">
				{error}
			</div>
		</div>
	);
};

import {useContext, useEffect, useState} from "react";
import {authContext} from "../context/auth";

export default function usePlaceHolders(promptId) {
    const [placeHolders, setPlaceHolders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const {token} = useContext(authContext);

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        setError(null);

        (async () => {
            try {
                const response = await fetch(process.env.REACT_APP_ENDPOINT + '/placeholders?promptId=' + promptId, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                });
                if (response.status === 200) {
                    const json = await response.json();
                    isMounted && setPlaceHolders(json.placeHolders);
                }
                else {
                    isMounted && setError("Failed to fetch your placeholders.");
                }
            }
            catch (e) {
                console.error(e);
                isMounted && setError("Failed to fetch your placeholders.");
            }
            finally {
                isMounted && setLoading(false);
            }
        })();

        return () => {
            isMounted = false;
        }
    }, [promptId]);


    async function deletePlaceHolder(placeHolderId) {
        try {
            const response = await fetch(process.env.REACT_APP_ENDPOINT + '/placeHolders/' + placeHolderId, {
                method: "DELETE",
                headers: {
                    "Authorization": "Bearer " + token
                }
            });
            if (response.status === 204) {
                setPlaceHolders(prev => {
                    return [...prev].filter(value => value.id !== placeHolderId);
                });
            }
        }
        catch (e) {
            console.error(e);
            setError("Failed to delete message.");
        }
    }

    return {
        loading,
        error,
        placeHolders,
        setPlaceHolders,
        deletePlaceHolder
    };
};
import Container from "../../components/Container";
import ProcessFile from "../../components/ProcessFile";
import {PlaygroundProvider} from "../../context/playgroundInputs";

export default function Playground() {
    return (
        <Container>
            <ProcessFile />
        </Container>
    );
};
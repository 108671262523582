import Prompt from "./Prompt";
import {useContext} from "react";
import {promptsContext} from "../../context/prompts";

export default function PromptSelector({promptId, setPromptId}) {
	const {prompts: availablePrompts} = useContext(promptsContext);

	function onChange(evt) {
		setPromptId(evt.target.value);
	}

	return (
		<div className={"mb-3"}>
			<label htmlFor="promptId" className={"form-label"}>Prompt:</label>
			<select className={"form-control"} id="promptId" value={promptId} onChange={onChange} required={true}>
				<option value="">Select a prompt</option>
				{availablePrompts.map(value => {
					return <option value={value.id} key={value.id}>{value.name}</option>;
				})}
			</select>
		</div>
	);
};

import button from "bootstrap/js/src/button";
import {useContext, useEffect, useState} from "react";
import {authContext} from "../../context/auth";
import {apiKeyContext} from "../../context/apiKey";
import Spinner from "../../components/Spinner";

export default function RedoFailedLines({job, fetchJob}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const {token} = useContext(authContext);
    const {openAiApiKey, anthropicApiKey, mistralApiKey} = useContext(apiKeyContext);

    async function redoFailedLines() {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(process.env.REACT_APP_ENDPOINT + '/jobs/' + job.id + '/redoFailedLines', {
                method: "POST",
                body: JSON.stringify({
                    openAiApiKey,
                    anthropicApiKey,
                    mistralApiKey
                }),
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            });
            if (response.status !== 204) {
                setError("Failed to redo failed lines.");
            }
            else {
                await fetchJob();
            }
        }
        catch (e) {
            console.error(e);
            setError("Failed to redo failed lines.");
        }
    }

    useEffect(() => {
        if (['In queue to redo failed lines', 'Redoing failed lines'].includes(job.status)) {
            setLoading(true);
        }
        else {
            setLoading(false);
        }
    }, [job.status]);
    return (
        <button className={"btn btn-primary btn-sm"} type={"button"} disabled={loading || job.status !== "Finished" || job.progress?.failed <= 0} onClick={redoFailedLines}>{loading && <Spinner small={true} />} Redo failed lines</button>
    )
}
import {useContext, useState} from "react";
import {authContext} from "../../context/auth";
import {saveAs} from "file-saver";

export default function ExportPrompt({prompt, placeHolders}) {
	const {token} = useContext(authContext);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);


	async function exportPrompt() {
		setLoading(true);
		setError(null);

		try {
			const response = await fetch(process.env.REACT_APP_ENDPOINT + '/prompts/' + prompt.id + '/export', {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + token
				}
			});
			if (response.status === 200) {
				const blob = await response.blob();
				saveAs(blob, `${prompt.name}.json`);
			}
		}
		catch (e) {
			console.error(e);
		}

		setLoading(false);
		console.log(prompt, placeHolders);
	}
	return (
		<>
			<button type="button" className="btn btn-secondary btn-sm" onClick={exportPrompt}>
				Export prompt
			</button>
		</>
	);
}

import {createContext, useEffect, useRef, useState} from "react";
import usePlaceHolders from "../hooks/usePlaceHolders";

export const playgroundContext = createContext({
	promptId: "",
	setPromptId: (promptId) => {},
	jobPlaceHolders: [],
	setJobPlaceHolders: (jobPlaceHolders) => {},
	columns: [],
	setColumns: (columns) => {},
	rows: [],
	setRows: (rows) => {},
	model: "",
	setModel: (model) => {},
	placeHolders: [],
	file: null,
	setFile: (file) => {}
});
export const PlaygroundProvider = function ({children}) {
	const [promptId, setPromptId] = useState("");
	const [jobPlaceHolders, setJobPlaceHolders] = useState( []);
	const [columns, setColumns] = useState([]);
	const [rows, setRows] = useState([]);
	const [model, setModel] = useState("");
	const {placeHolders} = usePlaceHolders(promptId);
	const [file, setFile] = useState(null);

	useEffect(() => {
		setJobPlaceHolders([]);
	}, [promptId]);

	return (
		<playgroundContext.Provider value={{
			promptId, setPromptId,
			jobPlaceHolders, setJobPlaceHolders,
			columns, setColumns,
			rows, setRows,
			model, setModel,
			placeHolders, file, setFile
		}}>
			{children}
		</playgroundContext.Provider>
	);
};

import {useContext, useEffect, useState} from "react";
import {authContext} from "../../context/auth";
import DeleteMessage from "./DeleteMessage";
import Warning from "../../components/Alerts/Warning";

export default function Message({message, placeHolders, deleteMessage, editMessage}) {
	const {token} = useContext(authContext);
	const [warnings, setWarnings] = useState([]);

	useEffect(() => {
		const abortController = new AbortController();

		(async () => {
			try {
				await fetch(process.env.REACT_APP_ENDPOINT + '/messages/' + message.id, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + token
					},
					signal: abortController.signal,
					body: JSON.stringify({
						type: message.type,
						text: message.text
					})
				});
			}
			catch (e) {
				console.error(e);
			}
		})();

		return () => {
			abortController.abort();
		};
	}, [message.type, message.text]);

	useEffect(() => {
		setWarnings([]);

		const placeHoldersInMessage = [...message.text.matchAll(/\{\{(.*?)}}/g)].map(value => value[1]);

		for (const placeHolder of placeHoldersInMessage) {
			if (!placeHolders.map(value => value.name).includes(placeHolder)) {
				setWarnings(prev => {
					return [...prev, `Did you forget to add "${placeHolder}" as a placeholder?`]
				})
			}
		}
	}, [message.text, placeHolders])

	return (
		<>
			{warnings.map((value, index) => {
				return <Warning key={index} message={value} />;
			})}
			<div className={"border shadow-sm p-3 d-flex justify-content-between gap-3 align-items-center"}>
				<div>
					<button className={"btn btn-secondary"} onClick={evt => {
						editMessage(message.id, {
							type: message.type === "user" ? "assistant" : "user"
						})
					}}>{message.type}</button>
				</div>
				<div className={"flex-fill"}>
				<textarea dir={"auto"} className={"form-control"}
						  placeholder={message.type === "user" ? "Enter a user message here." : "Enter an assistant message here."}
						  value={message.text} onChange={evt => {
					editMessage(message.id, {
						text: evt.target.value
					});
				}}></textarea>
				</div>
				<div>
					<DeleteMessage message={message} deleteMessage={deleteMessage}/>
				</div>
			</div>
		</>

	);
}

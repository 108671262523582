import Messages from "./Messages";
import {useContext, useEffect, useState} from "react";
import {authContext} from "../../context/auth";
import useBackDrops from "../../hooks/useBackDrops";
import PlaceHolders from "./PlaceHolders";
import Warning from "../../components/Alerts/Warning";
import offcanvas from "bootstrap/js/src/offcanvas";

export default function EditPrompt({prompt, editPrompt, placeHolders, setPlaceHolders, loading, error, deletePlaceHolder}) {
	const {token} = useContext(authContext);
	const [warnings, setWarnings] = useState([]);

	useBackDrops();

	useEffect(() => {
		const abortController = new AbortController();

		(async () => {
			try {
				await fetch(process.env.REACT_APP_ENDPOINT + '/prompts/' + prompt.id, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + token
					},
					signal: abortController.signal,
					body: JSON.stringify({
						system: prompt.system,
						name: prompt.name,
						maxTokens: prompt.maxTokens,
						topP: prompt.topP,
						temperature: prompt.temperature
					})
				});
			}
			catch (e) {
				console.error(e);
			}
		})();

		return () => {
			abortController.abort();
		};
	}, [prompt.system, prompt.name, prompt.maxTokens, prompt.topP, prompt.temperature, placeHolders]);

	useEffect(() => {
		setWarnings([]);

		const placeHoldersInMessage = [...prompt.system.matchAll(/\{\{(.*?)}}/g)].map(value => value[1]);

		for (const placeHolder of placeHoldersInMessage) {
			if (!placeHolders.map(value => value.name).includes(placeHolder)) {
				setWarnings(prev => {
					return [...prev, `Did you forget to add "${placeHolder}" as a placeholder?`]
				})
			}
		}
	}, [prompt.system, placeHolders]);

	const id = `editPrompt${prompt.id}`;

	return (
		<>
			<button type="button" className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target={`#${id}`}>
				Edit prompt
			</button>

			<div className="modal fade" id={id} tabIndex="-1" aria-labelledby={`${id}Label`} aria-hidden="true">
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id={`${id}Label`}>Edit prompt</h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body">

							<div className={"mb-3"}>
								<label htmlFor={`name${prompt.id}`} className={"form-label"}>Name:</label>
								<input type={"text"} id={`name${prompt.id}`} placeholder={"Prompt number 1"}
									   className={"form-control"} value={prompt.name || ''} onChange={evt => {
									editPrompt(prompt.id, {
										name: evt.target.value
									});
								}}/>
							</div>
							<div className={"mb-3"}>
								{warnings.map((value, index) => {
									return <Warning key={index} message={value} />;
								})}
								<label htmlFor={`systemRole${prompt.id}`} className={"form-label"}>System:</label>
								<textarea dir={"auto"} id={`systemRole${prompt.id}`} placeholder={"You are a helpful assistant."}
										  className={"form-control"} value={prompt.system || ''} onChange={evt => {
									editPrompt(prompt.id, {
										system: evt.target.value
									});
								}}></textarea>
							</div>
							<div className={"row mb-3"}>
								<div className={"col"}>
									<label className={"form-label"} htmlFor={`maxTokens${prompt.id}`}>Max tokens:</label>
									<input className={"form-control"} type="number" id={`maxTokens${prompt.id}`} step={1} value={prompt.maxTokens || ''} required={false} placeholder={"Leave empty for the default"} onChange={evt => {
										console.log(evt.target.value);
										editPrompt(prompt.id, {
											maxTokens: evt.target.value
										});
									}}/>
								</div>
								<div className={"col"}>
									<label className={"form-label"} htmlFor={`temperature${prompt.id}`}>Temperature:</label>
									<input className={"form-control"} type="number" min={0} max={1} id={`temperature${prompt.id}`} step={0.1}
										   value={prompt.temperature || ''} required={false}
										   placeholder={"Leave empty for the default"} onChange={evt => {
											   if (evt.target.value > 1) {
												   evt.preventDefault();
											   }
											   else {
												   editPrompt(prompt.id, {
													   temperature: evt.target.value
												   });
											   }
									}}/>
								</div>
								<div className={"col"}>
									<label className={"form-label"} htmlFor={`topP${prompt.id}`}>Top p:</label>
									<input className={"form-control"} type="number" min={0} max={1} id={`topP${prompt.id}`} step={0.1}
										   value={prompt.topP || ''} required={false}
										   placeholder={"Leave empty for the default"} onChange={evt => {
										if (evt.target.value > 1) {
											evt.preventDefault();
										}
										else {
											editPrompt(prompt.id, {
												topP: evt.target.value
											});
										}
									}}/>
								</div>
							</div>
							<hr/>
							<Messages promptId={prompt.id} placeHolders={placeHolders}/>
							<hr/>
							<PlaceHolders promptId={prompt.id} error={error} loading={loading}
										  placeHolders={placeHolders} setPlaceHolders={setPlaceHolders}
										  deletePlaceHolder={deletePlaceHolder}/>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
						</div>
					</div>
				</div>
			</div>
			{/*<div classNameName={"border-bottom p-3"}>
				<div classNameName={"mb-3"}>
					<label htmlFor={`name${prompt.id}`} classNameName={"form-label"}><b>Name:</b></label>
					<input type={"text"} id={`name${prompt.id}`} placeholder={"Prompt number 1"}
						   classNameName={"form-control"} value={prompt.name || ''} onChange={evt => {
						editPrompt(prompt.id, {
							name: evt.target.value
						});
					}}/>
				</div>
				<div classNameName={"mb-3"}>
					<label htmlFor={`systemRole${prompt.id}`} classNameName={"form-label"}>System:</label>
					<textarea id={`systemRole${prompt.id}`} placeholder={"You are a helpful assistant."}
							  classNameName={"form-control"} value={prompt.system || ''} onChange={evt => {
						editProm pt(prompt.id, {
							system: evt.target.value
						});
					}}></textarea>
				</div>

				<hr/>
				<Messages promptId={prompt.id} deletePrompt={deletePrompt}/>
			</div>*/}
		</>

	);
}

import {useContext, useState} from "react";
import Spinner from "../Spinner";
import {authContext} from "../../context/auth";

export default function DeleteAccount() {
    const [loading, setLoading] = useState(false);
    const {token, setToken} = useContext(authContext);

    async function onSubmit(evt) {
        evt.preventDefault();
        setLoading(true);
        try {
            const response = await fetch(process.env.REACT_APP_ENDPOINT + "/auth", {
                method: "DELETE",
                headers: {
                    "Authorization": 'Bearer ' + token
                }
            });
            if (response.status === 204) {
                setToken(null);
            }
        }
        catch (e) {
            console.error(e);
        }
        setLoading(false);
    }

    return (
        <form onSubmit={onSubmit}>
            <div className={"form-check mb-3"}>
                <input type="checkbox" id={"agree1"} className={"form-check-input"} required={true}/>
                <label htmlFor="agree1" className={"form-check-label"}>I understand I won't be able to use this service any longer.</label>
            </div>
            <div className={"form-check mb-3"}>
                <input type="checkbox" id={"agree2"} className={"form-check-input"} required={true}/>
                <label htmlFor="agree2" className={"form-check-label"}>I understand my personal information will be permanently deleted from our database.</label>
            </div>
            <button type={"submit"} disabled={loading} className={"btn btn-danger w-100"}>{loading && <Spinner small={true} />} Delete my account</button>
        </form>
    )
}
import {createContext, useEffect, useState} from "react";

export const authContext = createContext({
	token: null,
	setToken: (token) => {},
	user: {},
	setUser: (user) => {},
	loading: false
});

export function AuthProvider({children}) {
	const [token, setToken] = useState(null);
	const [user, setUser] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		(async () => {
			setLoading(true);

			const tokenFromLocalStorage = localStorage.getItem('token');
			if (tokenFromLocalStorage) {
				const response = await fetch(process.env.REACT_APP_ENDPOINT + '/auth', {
					method: "GET",
					headers: {
						"Authorization": "Bearer " + tokenFromLocalStorage
					}
				});
				if (response.status === 200) {
					const {user} = await response.json();
					setToken(tokenFromLocalStorage);
					setUser(user);
				}
			}

			setLoading(false);
		})();
	}, []);

	return (
		<authContext.Provider value={{
			token,
			user,
			setToken,
			setUser,
			loading
		}}>
			{children}
		</authContext.Provider>
	);
}

import Spinner from "../Spinner";
import FileSelector from "../FileSelector/FileSelector";
import ColumnSelector from "../ColumnSelector";
import PreviewFile from "../FileSelector/PreviewFile";
import LanguagePairSelector from "../LanguagePairSelector";
import PromptSelector from "../PromptSelector";
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import ExportResults from "../ExportResults";
import Result from "../Result";
import {apiKeyContext} from "../../context/apiKey";
import {authContext} from "../../context/auth";
import {flushSync} from "react-dom";
import ModelSelector from "../ModelSelector";
import TotalTokens from "../TotalTokens";
import Papa from "papaparse";
import { saveAs } from 'file-saver';
import PlaceHolderSelector from "../PlaceHolderSelector";
import usePlaceHolders from "../../hooks/usePlaceHolders";
import Error from "../Alerts/Error";
import Success from "../Alerts/Success";
import {playgroundContext} from "../../context/playgroundInputs";

export default function ProcessFile() {
	const [error, setError] = useState(null);
	const [status, setStatus] = useState('');
	const [success, setSuccess] = useState(null);
	const [loading, setLoading] = useState(false);

	const {columns, jobPlaceHolders, model, placeHolders, promptId, rows, setColumns, setJobPlaceHolders, setModel, setPromptId, setRows, file, setFile} = useContext(playgroundContext);

	const {token} = useContext(authContext);
	const {openAiApiKey, anthropicApiKey, mistralApiKey} = useContext(apiKeyContext);


	async function onSubmit(evt) {
		evt.preventDefault();
		setLoading(true);
		setError(null);
		setSuccess(null);

		try {
			setStatus('Uploading file...');
			const formData = new FormData();
			formData.append('file', file);
			const response = await fetch(process.env.REACT_APP_ENDPOINT + '/files', {
				method: "POST",
				headers: {
					'Authorization': 'Bearer ' + token
				},
				body: formData
			});
			if (response.status === 201) {
				const {fileId} = await response.json();
				setStatus('Starting job...');

				const response2 = await fetch(process.env.REACT_APP_ENDPOINT + '/jobs', {
					method: "POST",
					headers: {
						'Authorization': 'Bearer ' + token,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						fileId,
						promptId,
						jobPlaceHolders,
						model,
						openAiApiKey,
						anthropicApiKey,
						mistralApiKey
					})
				});
				if (response2.status === 201) {
					const {job} = await response2.json();
					console.log(job);
					setSuccess("Successfully started this job, with number:, " + job.id)
				}
				else {
					setError("Failed to start job.");
				}
			}
			else {
				setError("Failed to upload file.");
			}
		}
		catch (e) {
			console.error(e);
			setError('Failed to start job.');
		}
		setLoading(false);
		setStatus('');
	}

	function reset() {

	}
	return (
		<>
			<form onSubmit={onSubmit} className={loading ? "position-relative opacity-25": ""}>
				{error && <Error message={error} />}
				{success && <Success message={success} />}

				{loading &&
					<div className={"position-absolute m-auto h-100 w-100 d-flex justify-content-center align-items-center"}>
						<p>{status}</p>
						<Spinner />
					</div>
				}
				<FileSelector file={file} setFile={setFile} reset={reset} setColumns={setColumns} setRows={setRows}/>
				{columns.length > 0 && <PromptSelector setPromptId={setPromptId} promptId={promptId} />}
				{placeHolders.length > 0 && <PlaceHolderSelector columns={columns} promptId={promptId} placeHolders={placeHolders} jobPlaceHolders={jobPlaceHolders} setJobPlaceHolders={setJobPlaceHolders}/>}
				{(placeHolders.length === jobPlaceHolders.length && rows.length > 0 && placeHolders.length > 0) &&
					<>
						<PreviewFile rows={rows} placeHolders={jobPlaceHolders.filter(value => {
							return placeHolders.filter(value => value.isInFile).map(value => value.id).includes(value.placeHolderId);
						})} />
						<ModelSelector model={model} setModel={setModel} />
					</>
				}
				{model &&
					<>
						<hr />
						<button type={"submit"} className={"btn btn-success w-100"}>Start job</button>
					</>
				}

			</form>
		</>
	);
}

import RedoFailedLines from "./RedoFailedLines";
import {useContext, useEffect, useState} from "react";
import {authContext} from "../../context/auth";
import {NavLink} from "react-router-dom";
import ExportResults from "../IndividualJob/ExportResults";

export default function JobRow({job: initialJob, editJob}) {
    const [job, setJob] = useState(initialJob);
    const {token} = useContext(authContext);

    useEffect(() => {
        let timeoutId = null;
        let isMounted = true;

        if (job.status !== "Finished") {
            console.log("Setting timeout", job)
            timeoutId = setInterval(async () => {
                await fetchJob(isMounted);
            }, 5000);
        }



        return () => {
            console.log("Clearing timeout", job);
            clearInterval(timeoutId);
            isMounted = false;
        }
    }, [job.status]);

    async function fetchJob(isMounted = true) {
        try {
            const response = await fetch(process.env.REACT_APP_ENDPOINT + '/jobs/' + job.id, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            });
            if (response.status === 200) {
                const {job} = await response.json();
                isMounted && setJob(job);
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    return (
        <tr key={job.id}>
            <td>{job.status === "Finished" ? <NavLink to={'/jobs/' + job.id}>{job.id}</NavLink>: job.id}</td>
            <td>{job.fileName}</td>
            <td>{job.model}</td>
            <td className={"text-nowrap"}>{job.promptName}</td>
            <td>{job.status}</td>
            <td>{job.progress && job.progress.done + '/' + job.progress.total + ' (' + job.progress.failed + ')'}</td>
            <td>{job.created_at}</td>
            <td>{job.updated_at}</td>
            <td className={"text-nowrap"}>
                <RedoFailedLines fetchJob={fetchJob} job={job}/>
                <ExportResults job={job} />
            </td>
        </tr>
    );
}
import {useContext, useEffect, useState} from "react";
import Error from "../../components/Alerts/Error";
import {authContext} from "../../context/auth";
import Spinner from "../../components/Spinner";

export default function Results({jobId}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [results, setResults] = useState([]);
    const [jobPlaceHolders, setJobPlaceHolders] = useState([]);

    const {token} = useContext(authContext);

    useEffect(() => {
        const abortController = new AbortController();
        let isMounted = true;

        (async () => {
            setLoading(true);
            try {
                const urlParams = new URLSearchParams();
                urlParams.append('jobId', jobId);

                const responsePh = await fetch(process.env.REACT_APP_ENDPOINT + '/jobPlaceHolders?' + urlParams.toString(), {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                    signal: abortController.signal
                });
                if (responsePh.status === 200) {
                    const {jobPlaceHolders} = await responsePh.json();
                    isMounted && setJobPlaceHolders(jobPlaceHolders);
                }
                else {
                    throw new Error("Wrong status.");
                }

                const response = await fetch(process.env.REACT_APP_ENDPOINT + '/results?' + urlParams.toString(), {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                    signal: abortController.signal
                });
                if (response.status === 200) {
                    const {results} = await response.json();
                    isMounted && setResults(results);
                }
                else {
                    throw new Error("Wrong status.");
                }
            }
            catch (e) {
                console.error(e);
                isMounted && setError('Failed to fetch results.');
            }

            isMounted && setLoading(false);
        })();

        return () => {
            isMounted = false;
            abortController.abort();
        };
    }, [jobId]);

    console.log(jobPlaceHolders);
    console.log(results);
    return (
        <>
            {error && <Error message={error}/>}
            <div className="text-center">
                {loading && <Spinner/>}
            </div>

            {!loading &&
                <table className={"table table-sm table-light table-striped"}>
                    <caption className={"caption-top"}>Results</caption>
                    <thead className={"table-dark"}>
                    <tr>
                        {jobPlaceHolders.map(placeHolder => {
                            return <th>{placeHolder.placeHolderName}</th>
                        })}
                        <th className={"text-nowrap"}>Tokens (prompt, completion, total)</th>
                        <th>Result</th>
                    </tr>
                    </thead>
                    <tbody>
                    {results.map(result => {
                        return <tr className={result.failed ? 'table-danger': ''} key={result.id}>
                            {jobPlaceHolders.map(placeHolder => {
                                console.log(placeHolder.value);
                                if (placeHolder.isInFile) {
                                    return <td dir={"auto"} key={placeHolder.id}>{result.rowData[placeHolder.value]}</td>
                                } else {
                                    return <td dir={"auto"} key={placeHolder.id}>{placeHolder.value}</td>
                                }
                            })}
                            <td>{result.usage && result.usage.prompt_tokens + ', ' + result.usage.completion_tokens + ', ' + result.usage.total_tokens}</td>
                            <td dir={"auto"}>{result.result}</td>
                        </tr>
                    })}
                    </tbody>
                </table>
            }
        </>
    );
}
import {NavLink, useLocation} from "react-router-dom";
import {useContext} from "react";
import {authContext} from "../../context/auth";

export default function Navbar() {
    const location = useLocation();
    const {user} = useContext(authContext);

    let settingsActive  = false;

    if (location.pathname.startsWith('/settings')) {
        settingsActive = true;
    }

    return (
        <nav className="navbar navbar-expand-lg bg-white shadow-sm border mt-3 container">
            <div className="container-fluid">
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink to={'/playground'} className={({isActive}) => {
                                return isActive ? 'nav-link active' : 'nav-link'
                            }}>Playground</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/jobs'} className={({isActive}) => {
                                return isActive ? 'nav-link active' : 'nav-link'
                            }}>Jobs</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/prompts'} className={({isActive}) => {
                                return isActive ? 'nav-link active' : 'nav-link'
                            }}>Prompts</NavLink>
                        </li>
                        <li className="nav-item dropdown">
                            <a className={"nav-link dropdown-toggle"} href="#"
                               role="button" data-bs-toggle="dropdown" aria-expanded={"false"}
                               >
                                Settings
                            </a>
                            <ul className="dropdown-menu">
                                <li>
                                    <NavLink to={'/settings/apiKeys'} className={({isActive}) => {
                                        return isActive ? 'dropdown-item active' : 'dropdown-item'
                                    }}>Api keys</NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/settings/deleteMyAccount'} className={({isActive}) => {
                                        return isActive ? 'dropdown-item active' : 'dropdown-item'
                                    }}>Delete my account</NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/about'} className={({isActive}) => {
                                return isActive ? 'nav-link active' : 'nav-link'
                            }}>About</NavLink>
                        </li>
                        {Boolean(user.isAdmin) &&
                            <li className="nav-item">
                                <NavLink to={'/users'} className={({isActive}) => {
                                    return isActive ? 'nav-link active' : 'nav-link'
                                }}>Users</NavLink>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </nav>
    );
};
import {useContext, useState} from "react";
import Info from "../Alerts/Info";
import {apiKeyContext} from "../../context/apiKey";
import Error from "../Alerts/Error";
import Spinner from "../Spinner";

export default function ApiKey() {
	const {openAiApiKey, setOpenAiApiKey, anthropicApiKey ,setAnthropicApiKey, mistralApiKey, setMistralApiKey} = useContext(apiKeyContext);
	const [openAiState, setOpenAiState] = useState(openAiApiKey);
	const [anthropicState, setAnthropicState] = useState(anthropicApiKey);
	const [mistralState, setMistralState] = useState(mistralApiKey);
	const [errors, setErrors] = useState([]);
	const [loading, setLoading] = useState(false);

	async function onSubmit(evt) {
		evt.preventDefault();
		setErrors([]);
		setLoading(true);

		try {
			const response = await fetch(process.env.REACT_APP_ENDPOINT + '/test-keys', {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					openAiApiKey: openAiState,
					anthropicApiKey: anthropicState,
					mistralApiKey: mistralState
				})
			});
			const {errors} = await response.json();
			if (errors.length > 0) {
				setErrors(errors);
			}
			else {
				setAnthropicApiKey(anthropicState);
				setOpenAiApiKey(openAiState);
				setMistralApiKey(mistralState);
			}
		}
		catch (e) {
			console.error(e);
			setErrors(['Failed to reach the server.']);
		}

		setLoading(false);
	}

	return (
		<form onSubmit={onSubmit}>
			<Info message={"This will only be stored on your local machine (in the browser) and won't be saved on our back-end!"} />
			{errors.map((value, index) => <Error message={value} key={index}/>)}
			<div className={"mb-3"}>
				<label htmlFor="openAiApiKey" className="form-label">Open AI API key:</label>
				<input type={"text"} className={"form-control"} id={"openAiApiKey"} value={openAiState} onChange={(evt) => {
					setOpenAiState(evt.target.value);
				}}/>
			</div>
			<div className={"mb-3"}>
				<label htmlFor="anthropicApiKey" className="form-label">Anthropic API key:</label>
				<input type={"text"} className={"form-control"} id={"anthropicApiKey"} value={anthropicState} onChange={(evt) => {
					setAnthropicState(evt.target.value);
				}}/>
			</div>
			<div className={"mb-3"}>
				<label htmlFor="mistralApiKey" className="form-label">Mistral API key:</label>
				<input type={"text"} className={"form-control"} id={"mistralApiKey"} value={mistralState} onChange={(evt) => {
					setMistralState(evt.target.value);
				}}/>
			</div>
			<button className={"btn btn-success w-100"} type={"submit"} disabled={loading}>{loading && <Spinner small={true} />} Save</button>
		</form>
	);
}

import {useContext, useEffect, useState} from "react";
import {authContext} from "../../context/auth";
import AddPrompt from "../../pages/Prompts/AddPrompt";
import {promptsContext} from "../../context/prompts";
import Container from "../../components/Container";
import Info from "../../components/Alerts/Info";
import Error from "../../components/Alerts/Error";
import Spinner from "../../components/Spinner";
import EditPrompt from "./EditPrompt";
import DeletePrompt from "./DeletePrompt";
import TestPrompt from "./TestPrompt";
import usePlaceHolders from "../../hooks/usePlaceHolders";
import Prompt from "./Prompt";

export default function Prompts() {
	const {token} = useContext(authContext);
	const {prompts, setPrompts, loading, error: errorP} = useContext(promptsContext);

	const [error, setError] = useState(null);

	function editPrompt(promptId, prompt) {
		setPrompts(prev => {
			const newPrompts = [...prev];
			const index = newPrompts.findIndex(value => value.id === promptId);
			newPrompts[index] = {
				...newPrompts[index],
				...prompt
			};
			return newPrompts;
		});
	}

	async function deletePrompt(promptId) {
		setError(null);

		try {
			const response = await fetch(process.env.REACT_APP_ENDPOINT + '/prompts/' + promptId, {
				method: "DELETE",
				headers: {
					"Authorization": "Bearer " + token
				}
			});
			if (response.status === 204) {
				setPrompts(prev => {
					return prev.filter(value => value.id !== promptId);
				});
			}
			else {
				setError("Failed to delete prompt.");
			}
		}
		catch (e) {
			console.error(e);
			setError("Failed to delete prompt.");
		}
	}

	return (
		<Container>
			<AddPrompt setPrompts={setPrompts} />

			<hr />
			{error && <Error message={error} />}
			{errorP && <Error message={errorP} />}

			<div className="text-center">
				{loading && <Spinner />}
			</div>

			<table className={"table table-striped"}>
				<thead className={"table-dark"}>
				<tr>
					<th className={"w-100"} colSpan={2}>Prompts</th>
				</tr>
				</thead>
				<tbody>
				{prompts.map(value => {
					return <Prompt editPrompt={editPrompt} deletePrompt={deletePrompt} prompt={value} key={value.id}/>
				})}
				</tbody>
			</table>
		</Container>
	);
};

import {useContext, useState} from "react";
import {authContext} from "../../context/auth";
import Spinner from "../../components/Spinner";
import Error from "../../components/Alerts/Error";
import useCreateCollapse from "../../hooks/useCreateCollapse";

export default function AddPrompt({setPrompts}) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [name, setName] = useState('');

	const {token} = useContext(authContext);
	const {hideButtonRef, createButtonRef, collapseRef, open} = useCreateCollapse();

	async function onSubmit(evt) {
		evt.preventDefault();

		setError(null);
		setLoading(true);

		try {
			const response = await fetch(process.env.REACT_APP_ENDPOINT + '/prompts', {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + token
				},
				body: JSON.stringify({prompt: {system: '', name: ''}})
			});
			if (response.status === 201) {
				const {prompt} = await response.json();
				setPrompts(prev => {
					return [...prev, {
						system: '',
						name: name,
						id: prompt.id
					}];
				});
				setName('');
			}
			else {
				setError("Failed to create prompt.");
			}
		}
		catch (e) {
			console.error(e);
			setError("Failed to create prompt.");
		}
		finally {
			setLoading(false);
		}
	}

	return (
		<>
			{error && <Error message={error}/>}
			<form onSubmit={onSubmit} id={"addPromptForm"} className={"collapse"} ref={collapseRef}>
				<div className={"mb-3"}>
					<label htmlFor={"name"} className={"form-label"}>Name:</label>
					<input type={"text"} className={"form-control"} value={name} onChange={evt => {
						setName(evt.target.value);
					}} id={"name"} required={true}/>
				</div>
			</form>
			<div className={"d-flex"}>
				<button type={"button"} className={"btn btn-secondary me-2  " + (open ? "" : "d-none")}
						ref={hideButtonRef}>Hide</button>
				<button type={open ? "submit" : "button"} ref={createButtonRef} className={"btn btn-success w-100"}
						form={"addPromptForm"} disabled={loading}>{loading ? <Spinner small={true}/> : 'Create prompt'}</button>
			</div>
		</>
	);
};

import {useContext} from "react";
import {apiKeyContext} from "../../context/apiKey";

const openAiModels = ['o1-mini', 'o1-preview', 'gpt-4o-mini', 'gpt-4o', 'gpt-4', 'gpt-4-turbo-preview', 'gpt-3.5-turbo'];
const anthropicModels = ['claude-3-5-sonnet-latest', 'claude-3-5-haiku-latest', 'claude-3-haiku-20240307', 'claude-3-sonnet-20240229', 'claude-3-opus-latest'];
const mistralModels = ['mistral-small-latest', 'mistral-medium-latest', 'mistral-large-latest'];

export default function ModelSelector({model, setModel}) {
    const {openAiApiKey, anthropicApiKey, mistralApiKey} = useContext(apiKeyContext);

    const models = [];

    if (openAiApiKey) {
        models.push(...openAiModels);
    }
    if (anthropicApiKey) {
        models.push(...anthropicModels);
    }
    if (mistralApiKey) {
        models.push(...mistralModels);
    }

    return (
        <div>
            <label htmlFor="model" className={"form-label"}>Model:</label>
            <select id="model" className={"form-select"} required={true} value={model} onChange={evt => {
                setModel(evt.target.value);
            }}>
                <option value="">Select an engine</option>
                {models.map((value, index) => {
                    return <option value={value} key={index}>{value}</option>;
                })}
            </select>
        </div>
    );
}
import {useContext, useState} from "react";
import {authContext} from "../../context/auth";
import Spinner from "../../components/Spinner";
import Error from "../../components/Alerts/Error";
import useCreateCollapse from "../../hooks/useCreateCollapse";

export default function AddPlaceHolder({setPlaceHolders, promptId}) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const {hideButtonRef, createButtonRef, collapseRef, open} = useCreateCollapse();
	const [name, setName] = useState('');
	const [isInFile, setIsInFile] = useState(true);

	const {token} = useContext(authContext);

	async function onSubmit(evt) {
		evt.preventDefault();
		setError(null);
		setLoading(true);

		try {
			const response = await fetch(process.env.REACT_APP_ENDPOINT + '/placeHolders', {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + token
				},
				body: JSON.stringify({promptId: promptId, name, isInFile})
			});
			if (response.status === 201) {
				const {placeHolder} = await response.json();
				setPlaceHolders(prev => {
					return [...prev, placeHolder];
				});
				setName('');
				setIsInFile(true);
			}
			else if (response.status === 409) {
				setError('You already have a placeholder with this name.')
			}
			else {
				setError("Failed to create placeholder.");
			}
		}
		catch (e) {
			console.error(e);
			setError("Failed to create placeholder.");
		}
		finally {
			setLoading(false);
		}
	}

	return (
		<>
			{error && <Error message={error}/>}
			<form onSubmit={onSubmit} id={"addPlaceHolderForm" + promptId} className={"collapse"} ref={collapseRef}>
				<div className={"mb-3"}>
					<label htmlFor={"placeHolderName" + promptId} className={"form-label"}>Name:</label>
					<input type={"text"} className={"form-control"} value={name} onChange={evt => {
						setName(evt.target.value);
					}} id={"placeHolderName" + promptId} required={true}/>
				</div>
				<div className={"form-check mb-3"}>
					<input type="checkbox" id={"isInFile" + promptId} className={"form-check-input"} checked={isInFile} onChange={evt => {
						setIsInFile(evt.target.checked);
					}}/>
					<label htmlFor={"isInFile" + promptId} className={"form-check-label"}>Is in file</label>
				</div>
			</form>
			<div className={"d-flex"}>
				<button type={"button"} className={"btn btn-secondary me-2  " + (open ? "" : "d-none")}
						ref={hideButtonRef}>Hide
				</button>
				<button type={open ? "submit" : "button"} ref={createButtonRef} className={"btn btn-success w-100"}
						form={"addPlaceHolderForm" + promptId} disabled={loading}>{loading ?
					<Spinner small={true}/> : 'Add placeholder'}</button>
			</div>
		</>
	);
}

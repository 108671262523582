import Container from "../../components/Container";
import Spinner from "../../components/Spinner";
import {useContext, useEffect, useState} from "react";
import {authContext} from "../../context/auth";
import Error from "../../components/Alerts/Error";
import RedoFailedLines from "./RedoFailedLines";
import JobRow from "./JobRow";

export default function Jobs() {
    const [loading, setLoading] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [error, setError] = useState(null);

    const {token} = useContext(authContext);

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        setError(null);
        const abortController = new AbortController();

        (async () => {
            try {
                const response = await fetch(process.env.REACT_APP_ENDPOINT + '/jobs', {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                    signal: abortController.signal
                });
                if (response.status === 200) {
                    const json = await response.json();
                    console.log(json);
                    isMounted && setJobs(json.jobs);
                }
                else {
                    isMounted && setError("Failed to fetch your jobs.");
                }
            }
            catch (e) {
                console.error(e);
                if (e.name !== "AbortError") {
                    isMounted && setError("Failed to fetch your jobs.");
                }
            }
            finally {
                isMounted && setLoading(false);
            }
        })();

        return () => {
            isMounted = false;
            abortController.abort();
        }
    }, []);

    function editJob(jobId, job) {
        setJobs(prev => {
            const newS = [...prev];
            newS[newS.findIndex(value => value.id === jobId)] = job;
            return newS;
        });
    }
    return (
        <Container>
            {error && <Error message={error} />}
            <div className="text-center">
                {loading && <Spinner />}
            </div>

            {!loading && <table className={"table table-striped"}>
                <caption className={"caption-top"}>Jobs</caption>
                <thead className={"table-dark"}>
                <tr>
                    <th>#</th>
                    <th>File</th>
                    <th>Model</th>
                    <th>Prompt</th>
                    <th>Status</th>
                    <th  className={"text-nowrap"}>Progress (done/total (failed))</th>
                    <th>Stamp</th>
                    <th>Last update</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {jobs.map(value => {
                    return <JobRow job={value} key={value.id} editJob={editJob}/>;
                })}
                </tbody>
            </table>}
        </Container>
    )
}
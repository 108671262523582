import {useContext, useEffect, useState} from "react";
import Message from "./Message";
import Error from "../../components/Alerts/Error";
import Spinner from "../../components/Spinner";
import {authContext} from "../../context/auth";
import AddMessage from "./AddMessage";

export default function Messages({promptId, placeHolders}) {
	const [messages, setMessages] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const {token} = useContext(authContext);

	useEffect(() => {
		let isMounted = true;
		setLoading(true);
		setError(null);
		const abortController = new AbortController();

		(async () => {
			try {
				const response = await fetch(process.env.REACT_APP_ENDPOINT + '/messages?promptId=' + promptId, {
					headers: {
						'Authorization': 'Bearer ' + token
					},
					signal: abortController.signal
				});
				if (response.status === 200) {
					const json = await response.json();
					isMounted && setMessages(json.messages);
				}
				else {
					isMounted && setError("Failed to fetch your messages.");
				}
			}
			catch (e) {
				console.error(e);
				if (e.name !== "AbortError") {
					isMounted && setError("Failed to fetch your messages.");
				}
			}
			finally {
				setLoading(false);
			}
		})();

		return () => {
			isMounted = false;
			abortController.abort();
		}
	}, []);



	function editMessage(messageId, message) {
		setMessages(prev => {
			const newMessages = [...prev];
			const messageIndex = newMessages.findIndex(value => value.id === messageId);
			newMessages[messageIndex] = {
				...newMessages[messageIndex],
				...message
			};
			return newMessages;
		});
	}

	async function deleteMessage(messageId) {
		try {
			const response = await fetch(process.env.REACT_APP_ENDPOINT + '/messages/' + messageId, {
				method: "DELETE",
				headers: {
					"Authorization": "Bearer " + token
				}
			});
			if (response.status === 204) {
				setMessages(prev => {
					return [...prev].filter(value => value.id !== messageId);
				});
			}
		}
		catch (e) {
			console.error(e);
			setError("Failed to delete message.");
		}
	}

	return (
		<>
			{error && <Error message={error} />}
			<div className="text-center">
				{loading && <Spinner />}
			</div>

			{messages.map(value => {
				return <Message message={value} placeHolders={placeHolders} key={value.id} editMessage={editMessage} deleteMessage={deleteMessage}/>;
			})}

			{!loading && <AddMessage setMessages={setMessages} promptId={promptId} />}
		</>
	)
}
